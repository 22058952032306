import React from "react";
import { PageProps, graphql } from "gatsby";

import { Layout } from "../components/layout";
import { SEO } from "../components/seo";

type DataProps = {
  site: {
    buildTime: string;
  };
};
const linkingDemo: React.FC<PageProps<DataProps>> = ({ location }) => (
  <Layout location={location}>
    <SEO title="linking demo" />
    <a href="javascript:void(0);">TOP </a>
    <br />
    <a target="_self" rel="noopener" href="https://sites.menu.jp/support">
      TOP(絶対パス)
    </a>
    <br />
    <a target="_self" href="https://gifted-cray-0e9508.netlify.app/">
      top,
    </a>
    <br />
    <a target="_self" rel="noopener" href="/">
      TOP（相対パス）
    </a>
    <div style={{ display: "block" }}>
      <input type="text" style={{ height: "100px" }} />
    </div>
  </Layout>
);

export default linkingDemo;

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`;
